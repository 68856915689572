import {postConexion} from "./connection";

let tempObj = {
    //httpVerb: "GET",
};
export const get_deals = async (obj) => {
    tempObj = {
        resource: "v1/executions",
        ["per-page"]: obj?.per_page,
        ["from-date"]: obj?.from_date,
        ["to-date"]: obj?.to_date,

    }
    obj.page ? tempObj.page = obj?.page : null;
    const branch = await postConexion("bridge", tempObj);
    return branch.data;
};
export const send_deal_sf = async (scoringId, data) => {
    // const partial id = 'a3tOx000000GKZ3IAO';
    // const full id = 'a3tTQ000000K5jV';
    let url = `bridge/sf/${scoringId}`;
    const tempObj = {
        data: data
    }
    const res = await postConexion(url,tempObj);
    return res.message;
}
export const get_classifications = async () => {
    tempObj = {
        resource: "taxonomy/classifications"
    }
    const classifications = await postConexion("bridge/pd", tempObj);
    return classifications.data;
};
export const get_categories = async (obj) => {
    let url = `taxonomy/categories`;
    tempObj = {
        resource: url,
        classificationId: obj
    }
    const catergories = await postConexion("bridge/pd",tempObj);
    return catergories.data;
};
export const get_subtypes = async (obj) => {
    let url = `taxonomy/subtypes`;
    tempObj = {
        resource: url,
        categoryId: obj
    }
    const subtypes = await postConexion("bridge/pd",tempObj);
    return subtypes.data;
};
export const get_sizes = async (obj) => {
    let url = `taxonomy/sizes`;
    tempObj = {
        resource: url,
        subtypeId: obj
    }
    const sizes = await postConexion("bridge/pd",tempObj);
    return sizes.data;
};
export const get_manufacturers = async (obj) => {
    let url = `taxonomy/manufacturers`;
    tempObj = {
        resource: url,
        sizeClassId: obj
    }
    const manufacturers = await postConexion("bridge/pd",tempObj);
    return manufacturers.data;
};
export const get_models = async (obj) => {
    let url = `taxonomy/models`;
    tempObj = {
        resource: url,
        manufacturerId: obj.manufacturerId,
        year: obj.year
    }
    const models = await postConexion("bridge/pd",tempObj);
    return models.data;
};
export const get_configurations = async (obj) => {
    let url = `taxonomy/configurations`;
    tempObj = {
        resource: url,
        year: obj.year,
        modelId: obj.modelId,
        manufacturerId: obj.manufacturerId,
        sizeClassId: obj.sizeClassId,
        subtypeId: obj.subtypeId,
        categoryId: obj.categoryId
    }
    const configurations = await postConexion("bridge/pd",tempObj);
    return configurations.data;
};
export const get_vin = async (obj) => {
    let url = `verification/vin/${obj}`;
    tempObj = {
        resource: url,
    }
    const configurations = await postConexion("bridge/pd",tempObj);
    return configurations.data;
};
export const get_basicSpecs = async (obj) => {
    let url = `specs/basic`;
    tempObj = {
        resource: url,
        configurationId: obj
    }
    const specs = await postConexion("bridge/pd",tempObj);
    return specs.data;
};
export const get_bulkBasicSpecs = async (obj) => {
    let url = `specs/bulk`;
    tempObj = {
        resource: url,
        configurationIds: obj
    }
    const specs = await postConexion("bridge/pd",tempObj);
    return specs.data;
};
export const get_residualvalues = async (obj) => {
    let url = `residualvalues/residualvalues`;
    tempObj = {
        resource: url,
        configurationId: obj.configurationId,
        condition: obj.condition,
    }
    const residualValues = await postConexion("bridge/pd",tempObj);
    return residualValues.data;
};
export const get_ewModel = async (obj) => {
    let url = `taxonomy/models`;
    tempObj = {
        resource: url,
        modelId: obj,
    }
    const configurations = await postConexion("bridge/ew",tempObj);
    return configurations.data;
};
export const get_ewModels = async (obj) => {
    let url = `taxonomy/models`;
    tempObj = {
        resource: url,
        category: obj.category,
        manufacturer: obj.manufacturer,
        year: obj.year,
    }
    const residualValues = await postConexion("bridge/ew",tempObj);
    return residualValues.data;
};

export const get_ewBasicSpecs = async (obj) => {
    let url = `specs/basic`;
    tempObj = {
        resource: url,
        modelId: obj
    }
    const specs = await postConexion("bridge/ew",tempObj);
    return specs.data;
};

export const get_ewValue = async (obj) => {
    let url = `values/value`;
    tempObj = {
        resource: url,
        modelId: obj.modelId,
        year: obj.year
    }
    const value = await postConexion("bridge/ew",tempObj);
    return value.data;
};
export const get_residuals = async () => {
    const residuals = await postConexion("bridge/residuals");
    return residuals.data;
};
export const get_engines = async (obj) => {
    const url = `bridge/engines`;
    tempObj = {
        sizeClassId: obj.sizeClassId,
        assetYear: obj.assetYear
    }
    const residuals = await postConexion(url,tempObj);
    return residuals.data;
};
export const get_transmissions = async (obj) => {
    const url = `bridge/transmission`;
    tempObj = {
        sizeClassId: obj.sizeClassId,
        assetYear: obj.assetYear
    }
    const residuals = await postConexion(url,tempObj);
    return residuals.data;
};
export const get_ie = async (obj) => {
    const url = `bridge/ie`;
    tempObj = {
        sizeClassId: obj.sizeClassId,
        assetYear: obj.assetYear
    }
    const residuals = await postConexion(url,tempObj);
    return residuals.data;
};
export const get_ra = async (obj) => {
    const url = `bridge/ra`;
    tempObj = {
        sizeClassId: obj.sizeClassId,
        assetYear: obj.assetYear
    }
    const residuals = await postConexion(url,tempObj);
    return residuals.data;
};